import {
  Box,
  SimpleGrid,
  Center,
  LinkBox,
  Text,
  Heading,
  Flex,
  Stack,
  Spacer,
  Image,
  useBreakpointValue,
  Container,
} from "@chakra-ui/react"
import FlowIconPng from "../../assets/images/flow-logo.png"
import BloctoIconPng from "../../assets/images/blocto-logo.png"
import MarketIconPng from "../../assets/images/market-image.png"
import PackDropPng from "../../assets/images/packdrop-image.png"

import {ArrowForwardIcon} from "@chakra-ui/icons"

import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js"

export function Page() {
  const appInsights = useAppInsightsContext()
  useTrackEvent(appInsights, "indexPageVisit", false)
  return (
    <Box>
      {/* First row */}
      {HeroStackPart()}
      {/* Second row */}
      <Box
        borderWidth="2px"
        my={8}
        sx={{boxShadow: "rgb(0 0 0 / 99%) -10px 15px 10px"}}
      >
        <SimpleGrid columns={[1, 1, 2]}>
          {IconAndTextBox({
            icon: MarketIconPng,
            text:
              "Collect most brilliant moments in chess and trade them in the marketplace.",
          })}
          {IconAndTextBox({
            icon: FlowIconPng,
            text:
              "All combos are created and stored on Flow network, an eco-friendly, performance-optimized blockchain.",
            boxBackground: "black",
          })}
          {IconAndTextBox({
            icon: BloctoIconPng,
            text:
              "We use Blocto, a user friendly wallet provider which allows you full control over your transactions.",
            boxBackground: "black",
          })}
          {IconAndTextBox({
            icon: PackDropPng,
            text:
              "Packs are coming soon! Join the first drop in order to start your ChessCombo adventure.",
          })}
        </SimpleGrid>
      </Box>
      {/* Third row */}
      <Stack
        direction={{base: "column", sm: "row"}}
        minHeight={{md: "440px", sm: "0"}}
        maxHeight={{md: "440px"}}
        backgroundImage="linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(17,17,17,0.95) 55%,rgba(17,17,17,0.85) 75%, rgba(34,32,26,0.8) 99%),
            url('/chessboard_pattern2.png')"
        sx={{boxShadow: "rgb(0 0 0 / 99%) -10px 15px 10px"}}
      >
        <Flex py={8} px={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{base: "3xl", md: "4xl", lg: "5xl"}}>
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: useBreakpointValue({base: "20%", md: "30%"}),
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "primary.400",
                  zIndex: -1,
                }}
              >
                FIRST PACK DROP
              </Text>
              <br />{" "}
              <Text color={"primary.400"} as={"span"}>
                COMING SOON
              </Text>{" "}
            </Heading>
            <Text fontSize={{base: "md", lg: "lg"}} color={"gray.500"}>
              Your ChessCombo adventure starts here. The foundation.
            </Text>
          </Stack>
        </Flex>
        <Flex minWidth={{base: "200px", lg: "200px"}} flex={1}>
          <Image
            className="zoom"
            sx={{
              ".my-box:hover &": {
                transform: "scale(1.1)",
              },
            }}
            p={8}
            alt={"Pack Image"}
            objectFit={"contain"}
            src={"/base_pack.png"}
          />
        </Flex>
      </Stack>
    </Box>
  )
}

function IconAndTextBox({icon, text, boxBackground = "primary.300"}) {
  return (
    <Box borderWidth="1px">
      <Flex height="100%">
        <Box
          borderRight="2px"
          borderColor="gray.700"
          bg={boxBackground}
          minHeight="120px"
          minWidth="120px"
        >
          <Center height="100%" width="100%">
            <Image src={icon} alt="Icon" />
          </Center>
        </Box>
        <Center height="100%" mx="4">
          <Text textAlign="left">{text}</Text>
        </Center>
      </Flex>
    </Box>
  )
}

function HeroStackPart() {
  return (
    <Container
      sx={{boxShadow: "rgb(0 0 0 / 99%) -10px 15px 10px"}}
      maxW={"7xl"}
      borderWidth={2}
      borderColor="gray.700"
      backgroundImage="linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(17,17,17,0.95) 55%,rgba(17,17,17,0.85) 75%, rgba(34,32,26,0.8) 99%),
    url('/chessboard_pattern2.png')"
      pb={8}
    >
      <Stack
        align={"center"}
        justify={"center"}
        spacing={{base: 8, md: 10}}
        direction={{base: "column", md: "row"}}
      >
        <Flex
          flex={1}
          justify={["center", "center", "right"]}
          position={"relative"}
          w={"full"}
        >
          <video autoPlay muted loop playsInline>
            <source
              src="https://portalstorageaccprod.azureedge.net/images/gifs/home.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Flex>
        <Stack flex={3} spacing={{base: 5, md: 10}}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            textAlign={["center", "center", "left"]}
            fontSize={{base: "3xl", sm: "4xl", lg: "6xl"}}
            ml={8}
          >
            <Text as={"span"} color={"primary.300"}>
              Home of&nbsp;
            </Text>
            <Text as={"span"}>the</Text>
            <br />
            <Text as={"span"}>most&nbsp;</Text>
            <Text as={"span"} color={"primary.300"}>
              beautiful
            </Text>
            <br />
            <Text as={"span"} color={"primary.300"}>
              chess&nbsp;
            </Text>
            <Text as={"span"}>combinations</Text>
          </Heading>
          <Spacer />
          <Stack
            spacing={{base: 4, sm: 6}}
            direction={{base: "column", sm: "row"}}
          >
            <LinkBox
              border="2px"
              borderColor="primary.300"
              href="https://forms.chesscombo.com/"
              isExternal
              bg="gray.800"
              ml={8}
              _hover={{
                bg: ["gray.600"],
              }}
            >
              <Flex p={4}>
                <Text fontSize="2xl">Subscribe / Ask a question</Text>
                <Center ml={6}>
                  <ArrowForwardIcon fontSize="3xl" color="primary.300" />
                </Center>
              </Flex>
            </LinkBox>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}
