import {ApplicationInsights} from "@microsoft/applicationinsights-web"
import {ReactPlugin} from "@microsoft/applicationinsights-react-js"
import {createBrowserHistory} from "history"

const browserHistory = createBrowserHistory({basename: ""})
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${process.env.REACT_APP_IKEY}`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
    },
    enableCorsCorrelation: false,
  },
})
appInsights.loadAppInsights()
export {reactPlugin, appInsights}
