import {config} from "@onflow/fcl"

config()
  .put("env", process.env.REACT_APP_CHAIN_ENV)
  .put("accessNode.api", process.env.REACT_APP_ACCESS_NODE)
  .put("challenge.handshake", process.env.REACT_APP_WALLET_DISCOVERY)
  .put("0xFungibleToken", process.env.REACT_APP_CONTRACT_FUNGIBLE_TOKEN)
  .put("0xNonFungibleToken", process.env.REACT_APP_CONTRACT_NON_FUNGIBLE_TOKEN)
  .put("0xNFTStorefront", process.env.REACT_APP_NFTSTOREFRONT)
  .put("0xChessCombo", process.env.REACT_APP_CONTRACT_CHESS_COMBO)
  .put("0xFUSD", process.env.REACT_APP_CONTRACT_FUSD)
  .put("0xPackDropper", process.env.REACT_APP_CONTRACT_CHESS_COMBO)
