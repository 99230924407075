import React from "react"
import {RecoilRoot} from "recoil"
import {HashRouter as Router} from "react-router-dom"
import {ChakraProvider} from "@chakra-ui/react"
import theme from "../theme.js"
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js"
import {reactPlugin} from "../appInsights"

export function Providers({children}) {
  return (
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <RecoilRoot>
          <ChakraProvider theme={theme}>
            <Router>{children}</Router>
          </ChakraProvider>
        </RecoilRoot>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  )
}
