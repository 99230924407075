import "./global/config"
import React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import {Switch, Route} from "react-router-dom"

import {Providers} from "./global/providers.comp"
import {Page as Home} from "./pages/home"
// import {Page as User} from "./pages/user"
// import {Page as Market} from "./pages/market"
// import {Page as Packs} from "./pages/packs"
// import {Page as Pack} from "./pages/pack"
// import {Page as Combo} from "./pages/combo"
// import {Page as Edition} from "./pages/edition"
// import {Page as PackOpening} from "./pages/pack-opening"
import {Page as NotFound} from "./pages/not-found.page"
// import {Page as TransactionStatus} from "./pages/transaction-status"
// import {Page as News} from "./pages/news"
// import {Page as About} from "./pages/about"

import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

import "./font.css"
import "./customCss.css"
import {Box, Text} from "@chakra-ui/react"
import Header from "./parts/header.comp"

window.fcl = fcl
window.t = t

ReactDOM.render(
  <Providers>
    <Header />
    <div className="wrapper">
      <Switch>
        {/* <Route exact path="/combos/:comboId" component={Combo} />
        <Route
          exact
          path="/editions/:compilationId/:combinationId"
          component={Edition}
        />
        <Route exact path="/market" component={Market} />
        <Route exact path="/packs" component={Packs} />
        <Route exact path="/news" component={News} />
        <Route exact path="/about" component={About} />
        <Route exact path="/packs/:packId" component={Pack} />
        <Route exact path="/user/0x:address" component={User} />
        <Route
          exact
          path="/user/0x:address/pack/:packUniqueId"
          component={PackOpening}
        /> */}
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/transactionStatus" component={TransactionStatus} /> */}
        <Route component={NotFound} />
      </Switch>
    </div>
    <Box
      mt={16}
      p={8}
      borderColor="gray.600"
      backgroundColor="gray.800"
      height="100px"
      width="100%"
    >
      <Box margin="auto" width="100%" maxWidth="1256px">
        <Text fontSize="md" color="gray.300">
          © {new Date().getFullYear()} ChessCombo
        </Text>
      </Box>
    </Box>
  </Providers>,
  document.getElementById("root")
)

reportWebVitals()
