import {send, decode, script, args, arg, cdc} from "@onflow/fcl"
import {Address} from "@onflow/types"

const CODE = cdc`
  import FungibleToken from 0xFungibleToken
  import NonFungibleToken from 0xNonFungibleToken
  import FUSD from 0xFUSD
  import ChessCombo from 0xChessCombo
  import NFTStorefront from 0xNFTStorefront

  pub fun hasFUSD(_ address: Address): Bool {
    let receiver = getAccount(address)
      .getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
      .check()
    let balance = getAccount(address)
      .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
      .check()
    return receiver && balance
  }
  pub fun hasChessCombo(_ address: Address): Bool {
    return getAccount(address)
      .getCapability<&ChessCombo.Collection{NonFungibleToken.CollectionPublic, ChessCombo.ComboCollectionPublic}>(ChessCombo.CollectionPublicPath)
      .check()
  }
  pub fun hasStorefront(_ address: Address): Bool {
    return getAccount(address)
      .getCapability<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(NFTStorefront.StorefrontPublicPath)
      .check()
  }

  pub fun main(address: Address): {String: Bool} {
    let ret: {String: Bool} = {}
    ret["FUSD"] = hasFUSD(address)
    ret["ChessCombo"] = hasChessCombo(address)
    ret["StoreFront"] = hasStorefront(address)
    return ret
  }
`

export async function isAccountInitialized(address) {
  if (address == null) return Promise.resolve({})
  try {
    var response = await send([
      script(CODE),
      args([arg(address, Address)]),
    ]).then(decode)

    return response
  } catch (error) {
    console.log("error while sending isAccountInitialized", error)
    return Promise.resolve({})
  }
}
