export const LOADING = "LOADING"

// Exposed states of a Flow Transaction
export const IDLE = "IDLE"
export const PROCESSING = "PROCESSING"
export const SUCCESS = "SUCCESS"
export const ERROR = "ERROR"

// Exposed names of a Flow Transaction through Chess combo system
export const LISTING = "LISTING"
export const BUYING = "BUYING"
export const DELISTING = "DELISTING"
export const BUYING_PACK = "BUYING_PACK"

// How long to pause on a success or error message
// before transitioning back to an IDLE state.
export const IDLE_DELAY = 1000
