import {Box, Flex, Badge, Button, Link, Image, Spinner} from "@chakra-ui/react"
import Logo from "../assets/svg/logo-combo.svg"
import {Link as A} from "react-router-dom"
import {useState, Suspense} from "react"
import {useCurrentUser} from "../hooks/use-current-user.hook"

import UserSvg from "../assets/svg/user.svg"
import {useInitialized} from "../hooks/use-initialized.hook"
import {WarningIcon} from "@chakra-ui/icons"

const MenuItem = ({children, isLast, to = "/", ...rest}) => {
  return (
    <Flex
      mb={{base: isLast ? 0 : 8, sm: 0}}
      mr={{base: 0, sm: isLast ? 0 : 8}}
      display="block"
      {...rest}
    >
      {to.length > 2 ? (
        <Link as={A} to={to}>
          {children}
        </Link>
      ) : (
        <Box>{children}</Box>
      )}
    </Flex>
  )
}

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
)

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
)

const Header = props => {
  const [show, setShow] = useState(false)
  const toggleMenu = () => setShow(!show)
  const [user, loggedIn, {signUp, logIn, logOut}] = useCurrentUser()
  const initialized = useInitialized(user.addr)

  const logInFunc = () => {
    var x = logIn()
    console.log("result of login:", x)
  }
  return (
    <Box
      backgroundColor="gray.800"
      position="fixed"
      zIndex="3"
      left="0"
      right="0"
      top="0"
      boxShadow="rgb(0 0 0 / 99%) 0px 5px 10px"
      mb={2}
      px={4}
    >
      <Flex
        w="100%"
        pb={4}
        pt={4}
        as="nav"
        maxWidth="1256px"
        align="center"
        justify="space-between"
        wrap="wrap"
        margin="auto"
        color={["white", "white", "white", "white"]}
        {...props}
      >
        <Flex maxWidth="1256px" align="center">
          <Link href="/">
            <Image alt="ChessCombo logo" src={Logo} />
          </Link>
          <Badge
            m={2}
            variant="outline"
            colorScheme="orange"
          >{`${process.env.REACT_APP_ENV}`}</Badge>
        </Flex>

        {/* <Box display={{base: "block", md: "none"}} onClick={toggleMenu}>
          {show ? <CloseIcon /> : <MenuIcon />}
        </Box>

        <Box
          display={{base: show ? "block" : "none", md: "block"}}
          flexBasis={{base: "100%", md: "auto"}}
        >
          <Flex
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "row", "row", "row"]}
            pt={[4, 4, 0, 0]}
          >
            <MenuItem to="/market">Market</MenuItem>
            <MenuItem to="/packs">Packs</MenuItem>
            <MenuItem to="/news">News</MenuItem>
            <MenuItem to="/about">About</MenuItem>
            {loggedIn && (
              <MenuItem to={"/user/" + user.addr}>
                <Flex>
                  <Image
                    height="100%"
                    width="32px"
                    alt="User"
                    fill="white"
                    src={UserSvg}
                  />
                  {initialized.isInitialized || (
                    <WarningIcon w={10} h={10} color="red.400" ml={2} pb={1} />
                  )}
                </Flex>
              </MenuItem>
            )}
            <MenuItem isLast>
              {!loggedIn ? (
                <Button
                  size="sm"
                  color={["black", "black", "black", "black"]}
                  onClick={logInFunc}
                  bg={["white", "white", "white", "white"]}
                  _hover={{
                    bg: ["primary.100"],
                  }}
                >
                  Log In
                </Button>
              ) : (
                <>
                  <Button
                    variant="outline"
                    colorScheme="white"
                    onClick={logOut}
                    _hover={{
                      bg: ["primary.700"],
                    }}
                  >
                    Log Out
                  </Button>
                </>
              )}
            </MenuItem>
          </Flex>
        </Box> */}
      </Flex>
    </Box>
  )
}

export default function WrappedHeaderCluster() {
  return (
    <Suspense
      fallback={
        <Box
          backgroundColor="gray.800"
          position="fixed"
          zIndex="3"
          left="0"
          right="0"
          top="0"
          mb={2}
          px={4}
        >
          <Flex
            w="100%"
            pb={4}
            pt={4}
            as="nav"
            maxWidth="1256px"
            align="center"
            justify="space-between"
            wrap="wrap"
            margin="auto"
            color={["white", "white", "white", "white"]}
          >
            <Flex align="center">
              <Link href="/">
                <Image src={Logo} />
              </Link>
              <Badge
                m={2}
                variant="outline"
                colorScheme="orange"
              >{`${process.env.REACT_APP_ENV}`}</Badge>
              <Spinner></Spinner>
            </Flex>
          </Flex>
        </Box>
      }
    >
      <Header />
    </Suspense>
  )
}
